<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSliderSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-slider-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Crear slider
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Titulo"
            rules="required"
          >
            <b-form-group
              label="Titulo"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="sliderData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sub Title -->
          <validation-provider
            #default="validationContext"
            name="Sub titulo"
            rules="required"
          >
            <b-form-group
              label="Sub titulo"
              label-for="subtitle"
            >
              <b-form-input
                id="subtitle"
                v-model="sliderData.subtitle"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Title button -->
          <validation-provider
            #default="validationContext"
            name="Titulo boton"
            rules="required"
          >
            <b-form-group
              label="Titulo boton"
              label-for="title-button"
            >
              <b-form-input
                id="title-button"
                v-model="sliderData.titleButton"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Url button -->
          <validation-provider
            #default="validationContext"
            name="URL boton"
            rules="required"
          >
            <b-form-group
              label="URL boton"
              label-for="url-button"
            >
              <b-form-input
                id="url-button"
                v-model="sliderData.urlButton"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Image Desktop -->
          <validation-provider
            #default="validationContext"
            name="Imagen Desktop"
            rules="required"
          >
            <b-form-group
              label="Imagen Desktop"
              label-for="image-desktop"
            >
              <b-form-file
                id="image-desktop"
                ref="refInputElDesktop"
                v-model="sliderData.imageDesktop"
                :state="getValidationState(validationContext)"
                placeholder="Elegir imagen..."
                accept="image/*"
                @input="renderDesktop"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div
            v-if="showImageDesktop"
            class="my-1"
          >
            <b-img
              thumbnail
              fluid
              :src="showImageDesktop"
              alt="Image Desktop"
            />
          </div>

          <!-- Image Mobile -->
          <validation-provider
            #default="validationContext"
            name="Imagen Mobile"
            rules="required"
          >
            <b-form-group
              label="Imagen Mobile"
              label-for="image-mobile"
            >
              <b-form-file
                id="image-mobile"
                ref="refInputElMobile"
                v-model="sliderData.imageMobile"
                :state="getValidationState(validationContext)"
                placeholder="Elegir imagen..."
                accept="image/*"
                @input="renderMobile"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div
            v-if="showImageMobile"
            class="my-1"
          >
            <b-img
              thumbnail
              fluid
              :src="showImageMobile"
              alt="Image Mobile"
            />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormFile, BImg,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onUnmounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import elementStoreModule from '@/views/dramox/elements/elementStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BImg,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSliderSidebarActive',
    event: 'update:is-add-new-slider-sidebar-active',
  },
  props: {
    isAddNewSliderSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const USER_APP_STORE_MODULE_NAME = 'app-elements'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, elementStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const blankSliderData = {
      title: '',
      subtitle: '',
      urlButton: '',
      titleButton: '',
      imageDesktop: null,
      imageMobile: null,
    }
    const refInputElDesktop = ref(null)
    const refInputElMobile = ref(null)

    const showImageDesktop = ref(null)
    const showImageMobile = ref(null)

    const sliderData = ref(JSON.parse(JSON.stringify(blankSliderData)))
    const resetSliderData = () => {
      sliderData.value = JSON.parse(JSON.stringify(blankSliderData))
      showImageDesktop.value = null
      showImageMobile.value = null
    }

    const { inputImageRenderer: renderDesktop } = useInputImageRenderer(refInputElDesktop, base64 => {

      showImageDesktop.value = base64
    })

    const { inputImageRenderer: renderMobile } = useInputImageRenderer(refInputElMobile, base64 => {

      showImageMobile.value = base64
    })

    const onSubmit = () => {

      const formData = new FormData()
      formData.append('title', sliderData.value.title)
      formData.append('subtitle', sliderData.value.subtitle)
      formData.append('urlButton', sliderData.value.urlButton)
      formData.append('titleButton', sliderData.value.titleButton)
      formData.append('imageDesktop', sliderData.value.imageDesktop)
      formData.append('imageMobile', sliderData.value.imageMobile)

      store.dispatch('app-elements/addSlider', formData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-slider-sidebar-active', false)
          showNotification({ title: 'Creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSliderData)

    return {
      sliderData,
      refInputElDesktop,
      refInputElMobile,
      showImageDesktop,
      showImageMobile,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      renderDesktop,
      renderMobile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
