import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/roles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/role/${id}/edit`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, body) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/role/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRole(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`admin/role/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/role/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPermissions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/permissions')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateExchangeRate(ctx, { value }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/update/exchange-rate', { exchange_rate: value })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
