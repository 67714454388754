<template>
  <div>
    <add-slider-sidebar
      :is-add-new-slider-sidebar-active.sync="isAddNewSliderSidebarActive"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de roles</h3> <small> (Hay {{ totalRoles }} roles)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'role-create'}"
              >
                <span class="text-nowrap">Crear</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refRoleListTable"
        class="position-relative"
        :items="fetchRoles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: Role -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveRoleIcon(data.item.name)"
              size="18"
              class="mr-50"
              :class="`text-${resolveRoleVariant(data.item.name)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`role-row-${data.item.id}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'role-edit', params: { id: data.item.id }})"
            />
            <b-tooltip
              title="Editar"
              class="cursor-pointer"
              :target="`role-row-${data.item.id}-send-icon`"
            />

            <span v-can-permissions="'Editar rol'">
              <feather-icon
                :id="`role-row-${data.item.id}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="deleteRole(data.item)"
              />
              <b-tooltip
                title="Eliminar"
                :target="`role-row-${data.item.id}-preview-icon`"
              />
            </span>

          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import AddSliderSidebar from '@/views/dramox/elements/slider/AddSliderSidebar.vue'
import settingHandler from '@/views/dramox/setting/settingHandler'
import settingStoreModule from '@/views/dramox/setting/settingStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    AddSliderSidebar,
    BCard,
    BRow,
    BCol,
    BTable,
    BTooltip,
    BButton,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-settings'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, settingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const isAddNewSliderSidebarActive = ref(false)

    const {
      // fetchUsers,
      refetchData,

      tableColumns,
      refRoleListTable,
      fetchRoles,
      totalRoles,
      resolveRoleIcon,
      resolveRoleVariant,
    } = settingHandler()

    const deleteRole = item => {
      store.dispatch('app-settings/deleteRole', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(e => {
          console.log(e)
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      isAddNewSliderSidebarActive,
      // fetchUsers,
      tableColumns,
      refRoleListTable,
      fetchRoles,
      totalRoles,
      resolveRoleIcon,
      resolveRoleVariant,
      deleteRole,
    }
  },
}
</script>
