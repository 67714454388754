import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function settingHandler() {
  // Use toast
  const toast = useToast()

  const refRoleListTable = ref(null)
  const totalRoles = ref(0)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: false },
    { key: 'name', label: 'Rol', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]

  const refetchData = () => {
    refRoleListTable.value.refresh()
  }


  const fetchRoles = (ctx, callback) => {
    store
      .dispatch('app-settings/fetchRoles')
      .then(response => {
        const { results } = response.data.payload
        totalRoles.value = results.length
        callback(results)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveRoleIcon = role => {
    if (role === 'administrador') return 'SettingsIcon'
    if (role === 'vendedor') return 'ShoppingCartIcon'
    return 'UserIcon'
  }

  const resolveRoleVariant = role => {
    if (role === 'administrador') return 'danger'
    if (role === 'vendedor') return 'success'
    return 'primary'
  }

  return {
    fetchRoles,
    tableColumns,
    refRoleListTable,
    totalRoles,
    resolveRoleIcon,
    resolveRoleVariant,

    refetchData,
  }
}
